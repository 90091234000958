<template>
  <div class="tabs-menu-box">
    <a href="javascript:void(0)" v-for="(item, index) in dataList" :key="index" :class="['tabs-menu-item',index===value?'tabs-menu-action':'']" @click="onChange(index)">
      {{ item.title }}
    </a>
  </div>
</template>
<script>
import { Row, Col } from "element-ui";
export default {
  data() {
    return {};
  },
  props: {
    dataList: {
      type: Array,
      require: true,
    },
    value: {
      type: Number,
      require:0,
    },
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  methods: {
    onChange(index) {
      this.$emit("input",index);
    },
  },
};
</script>
<style lang="less" scoped="this api replaced by slot-scope in 2.5.0+">
.tabs-menu-box {
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: rgba(255,255,255, .5);
  font-size: 19px;
  .tabs-menu-item {
    padding: 0 30px;
    cursor:pointer;
    font-size: 18px;
    font-weight: 600;
    text-decoration:none;
    color: rgba(255,255,255,.5);
  }
  .tabs-menu-action{
      font-weight: bold;
      color: #fff;
  }
}
</style>