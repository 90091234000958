<template>
  <div class="main-box">
    <div class="head-back-box">
      <el-row justify="center" type="flex">
        <el-col :span="18">
          <div class="home-head-box">
            <div class="title-box">
              <img src="../assets/logo.png" class="logo-box" alt="" />
              <span>抖推客同城推</span>
            </div>
            <div class="head-right-box">
              <tabsMenu :dataList="menuList" v-model="tabsMenuIndex" />
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row justify="center" type="flex">
        <el-col :span="18">
          <div class="title-ig-box">
            <div class="title-lg-left">
              <div style="font-size: 30px; font-weight: 500;">
                2021短视频风口项目
              </div>
              <div  style="font-weight: 200;margin: 10px 0;font-family:'webfont';font-size:50px;transform: rotate(-2deg);-ms-transform: rotate(-2deg);-moz-transform: rotate(-2deg);-webkit-transform: rotate(-2deg);-o-transform: rotate(-5deg);">抖音-抖推客同城推</div>
              <div style="margin: 0 auto;;width:14em;transform: rotate(-2deg);-ms-transform: rotate(-2deg);-moz-transform: rotate(-2deg);-webkit-transform: rotate(-2deg);-o-transform: rotate(-2deg);">一个实体店与抖音流量紧密结合的营销解决方案,让您的店铺流量无限放大</div>
              <div class="tag" style="margin-top:20px;cursor:pointer" @click="onModel">立即获取项目资料</div>
            </div>
            <div class="title-ig-right">
              <img src="../assets/sd.png" class="title-ig-right-img" alt="" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
<!--    <el-row justify="center" type="flex" align="top">-->
<!--        <el-col :span="18">-->
<!--          <div style="height: 100px;margin-top: -50px;background: #fff;border-radius: 10px;box-shadow: 0px 1px 1rem 0px rgb(189 187 187);">-->
<!--              -->
<!--          </div>-->
<!--        </el-col>-->
<!--    </el-row>-->
    <el-row justify="center" type="flex" align="top" style="margin:100px 0 0">
        <el-col :span="18">
          <div style="font-size:50px;font-weight: bold;text-align: center"  id="item-0">
            抖推客项目介绍
          </div>
          <div style="display: flex;align-items: center;justify-content: center;margin-top: 60px">
            <div style="width: 50%;">
              <div style="padding-bottom: 20px;">
                <div style="font-size: 28px;font-weight: bold;color: #6e03bd;">引流到店</div>
                <div>到店顾客扫码，消费者领券一键帮商家发布抖音视频推广。 </div>
              </div>
              <div style="padding-bottom: 20px;">
                <div style="font-size: 28px;font-weight: bold;color: #6e03bd;">简单操作</div>
                <div>无需消费者拍视频，—键直接发布店铺视频，轻松推广。  </div>
              </div>
              <div style="padding-bottom: 20px;">
                <div style="font-size: 28px;font-weight: bold;color: #6e03bd;">快速裂变</div>
                <div>一个消费者裂变300-1000抖音播放量，N×300~500倍快速推广。</div>
              </div>
            </div>
            <div style="width: 50%;border: 1px solid #fff;">
              <img src="../assets/daodian.png" alt="" style="border-radius: 5px;height:300px">
            </div>
          </div>
        </el-col>
    </el-row>
    <el-row justify="center" type="flex" align="top" style="margin:100px 0 0"   id="item-1">
      <el-col :span="18">
        <div style="font-size:50px;font-weight: bold;text-align: center">
          抖推客同城推操作流程
        </div>
        <div style="align-items: center;justify-content: center;margin-top: 60px">
          <guideCard :data-list="guideList"/>
        </div>
      </el-col>
    </el-row>
    <el-row justify="center" type="flex" align="top" style="margin:100px 0 0"   id="item-2">
      <el-col :span="18">
        <div style="font-size:50px;font-weight: bold;text-align: center">
          抖推客同城推优势
        </div>
        <div style="align-items: center;justify-content: center;margin-top: 60px">
          <advantageCard :data-list="advantageList"/>
        </div>
      </el-col>
    </el-row>
    <el-row justify="center" type="flex" align="top" style="margin:100px 0 0"   id="item-3">
      <el-col :span="18">
        <div style="font-size:50px;font-weight: bold;text-align: center">
          抖推客同城推项目合作
        </div>
        <div style="align-items: center;justify-content: center;margin-top: 60px;position: relative">
          <tableCard></tableCard>
        </div>
      </el-col>
    </el-row>
    <el-row justify="center" type="flex" align="top" style="margin:100px 0 0"   id="item-4">
      <el-col :span="18">
        <div style="font-size:50px;font-weight: bold;text-align: center">
          抖推客同城推合作商户
        </div>
        <div style="align-items: center;justify-content: center;margin-top: 60px;position: relative;width: 100%">
          <shopCard :data-list="shopList"/>
        </div>
      </el-col>
    </el-row>
    <footBox style="margin-top: 100px" >
      <tabsMenu :dataList="menuList" v-model="tabsMenuIndex" />
    </footBox>
  </div>
</template>

<script>
import { Row, Col } from "element-ui";
// 头部菜单
import tabsMenu from "@/components/tabsMenu";
// 引导
import guideCard from "@/components/guideCard"
// 优势
import advantageCard from "@/components/advantageCard"
// 项目合作
import tableCard from "@/components/tableCard";
// 商户列表
import shopCard from "@/components/shopCard";
// 底部
import footBox from "@/components/footBox";
export default {
  name: "Home",
  data() {
    return {
      menuList: [
        {
          title: "项目介绍",
          path: "/",
        },
        {
          title: "操作流程",
          path: "/",
        },
        {
          title: "项目优势",
          path: "/",
        },
        {
          title: "项目合作",
          path: "/",
        },
        {
          title: "案例展示",
          path: "/",
        },
      ],
      tabsMenuIndex: 0,
      guideList:[
        {
          icon:'guide1.png',
          title:'到店扫码',
          msg:"消费者到店扫码 自动进入跳转页面 "
        },
        {
          icon:'guide2.png',
          title:"一键发布视频",
          msg:"一键轻松发布 无需消费者繁琐操作"
        },
        {
          icon:'guide3.png',
          title:"关注领劵",
          msg:"自动关注店家 抖音号并自行领券 "
        },
        {
          icon:'guide4.png',
          title:"快速裂变",
          msg:"快速裂变客流量 短时间提高曝光度"
        },
        {
          icon:'guide5.png',
          title:"霸屏引流",
          msg:"快速霸占同城视频页 极速引流"
        }
      ],
      advantageList:[
        {
          title:"一键发布",
          icon:"el-icon-s-promotion",
          msg:"消费者扫码一键上传视频，快速简洁"
        },
        {
          title:"宣传神器",
          icon:"el-icon-s-marketing",
          msg:"利用消费者的朋友圈快速裂变，极速推广"
        },
        {
          title:"节省成本",
          icon:"el-icon-s-finance",
          msg:"相比其他广告方式，大大减少投入成本"
        },
        {
          title:"批量视频",
          icon:"el-icon-s-finance",
          msg:"快速生成大量视频，宣传不重样"
        },
        {
          title:"多种素材",
          icon:"el-icon-s-finance",
          msg:"提供视频、音乐、海报、易拉宝等多种素材"
        },
        {
          title:"适用场景广",
          icon:"el-icon-s-finance",
          msg:"餐饮业、服装业、健身、旅游业、教育培训机构等"
        }
      ],
      shopList:[
        '1.jpg',
          '2.jpg',
        '3.jpg',
        '4.jpg',
        '5.jpg',
      ]
    };
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    tabsMenu,
    guideCard,
    advantageCard,
    tableCard,
    shopCard,
    footBox
  },
  methods:{
    onModel()
    {
        alert("联系电话：15571299098\n公司地址:湖北省孝感市保丽国际广场3栋25层")
    },
    goAnchor(selector) {
      console.log(selector)
      var anchor = this.$el.querySelector(selector)
      document.body.scrollTop = anchor.offsetTop
    }
  },
  watch:{
    tabsMenuIndex(index)
    {
      console.log(index)
      document.querySelector("#item-"+index).scrollIntoView(true);
    }
  }
};
</script>

<style lang="less" scoped="this api replaced by slot-scope in 2.5.0+">

.main-box {
  .head-back-box {
    background: url(../assets/head.web.bg.png) no-repeat no-repeat;
    background-size: 100% 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .home-head-box {
      display: flex;
      position: relative;
      align-items: center;
      .title-box {
        display: flex;
        align-items: center;
        .logo-box {
          width: 48px;
          height: 48px;
        }
        span {
          font-size: 26px;
          font-weight: bold;
          color: #fff;
        }
      }
      .head-right-box {
        flex: 1;
        height: 100%;
      }
    }
    .title-ig-box {
      flex: 1;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .title-lg-left {
        // flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // align-items: center;
        font-size: 40px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        .tag {
          margin: 0 auto;
          width: 17em;
          background: #f12870;
          font-size: 25px;
          padding: 10px 20px ;
          border-radius: 30px;
        }
      }
      .title-ig-right {
        padding: 60px 0;
        .title-ig-right-img {
          margin-left: 100px;
          height: 700px;
        }
      }
    }
  }
}

</style>