<template>
  <div style="display: flex;position: relative;margin-bottom: 20px;width: 100%;overflow-y: scroll">
    <img v-for="(item,index) in dataList" :style="{marginRight:(index===dataList.length-1)?'':'20px'}" :key="index" :src="require('@/assets/'+item)" alt="" style="flex:1;border: 3px solid #eee;border-radius:10px">
  </div>
</template>

<script>
import {Row,Col} from "element-ui"
export default {
  name: "shopCard",
  components:{
    [Row.name]:Row,
    [Col.name]:Col
  },
  props:{
    dataList:{
      type:Array,
      require:true
    }
  }
}
</script>

<style scoped>

</style>