<template>
      <el-table :show-header="false" header-align="center" :data="tableData" border>
        <el-table-column prop="title"  align="center"/>
        <el-table-column prop="title1"  align="center"/>
        <el-table-column prop="title2"  align="center"/>
        <el-table-column prop="title3"  align="center"/>
        <el-table-column prop="title4"  align="center"/>
        <el-table-column prop="title5"  align="center"/>
      </el-table>
</template>

<script>
import {Table,TableColumn} from "element-ui"
export default {
  name: "tableCard",
  data() {
    return {
      tableData: [{
        title: '合作项目',
        title1: '低级合作商',
        title2: '中级合作商',
        title3:'高级合作商',
        title4:'区级总代理',
        title5:'市级总代理'
      },
        {
          title: '技术支持',
          title1: '支持',
          title2: '支持',
          title3:'支持',
          title4:'支持',
          title5:'支持'
        },
        {
          title: '运营支持',
          title1: '支持',
          title2: '支持',
          title3:'支持',
          title4:'支持',
          title5:'支持'
        },
        {
          title: '服务器',
          title1: '',
          title2: '无需',
          title3:'',
          title4:'',
          title5:''
        },
        {
          title: '赠送扫码次数',
          title1: '20000次',
          title2: '50000次',
          title3:'100000次',
          title4:'150000次',
          title5:'200000次'
        },
        {
          title:"商户数量",
          title1: '无限制',
          title2: '无限制',
          title3:'无限制',
          title4:'无限制',
          title5:'无限制'
        }
      ]
    }
  },
  components:{
    [Table.name]:Table,
    [TableColumn.name]:TableColumn
  },
  methods:{
  }
}
</script>

<style scoped>

</style>