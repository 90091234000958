<template>
  <el-row>
    <el-col :span="8" v-for="(item,index) in dataList" :key="index" style="margin:30px 0">
        <div style="text-align: center">
          <img :src="require('@/assets/'+item.icon)" alt="" style="height: 150px">
          <div style="height: 40px;line-height: 40px">
            <span style="background: #f12870;color: #fff;font-weight: bold;font-size: 25px;width:1.3em;border-radius: 100%;display: inline-block;line-height: 1.3em">{{index+1}}</span>
            <span style="font-size: 25px;font-weight: bold;margin-left: 3px">{{item.title}}</span>
          </div>
          <div style="font-size:22px;font-weight: bold;margin-top: 5px">{{item.msg}}</div>
        </div>
    </el-col>
  </el-row>
</template>

<script>
import {Row,Col} from "element-ui"
export default {
  name: "index.vue",
  data () {
    return {

    }
  },
  components:{
    [Row.name]: Row,
    [Col.name]: Col,
  },
  props:{
    // 数据集
    dataList:{
      type:Array,
      require:true
    }
  }
}
</script>

<style scoped>

</style>