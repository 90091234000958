<template>
  <el-row>
    <el-col :span="8" v-for="(item,index) in dataList" :key="index" style="margin:30px 0">
      <div style="text-align: center">
        <div style="padding: 20px;border-radius: 100%;display: inline-block;box-shadow: rgb(189, 187, 187) 0px 1px 1rem 0px;margin-bottom: 10px">
          <i :class="item.icon" style="font-size: 40px;color: #fff;background: #f12870;padding: 20px;border-radius: 100%"></i>
        </div>
        <div style="height: 40px;line-height: 40px">
          <span style="font-size: 25px;font-weight: bold;margin-left: 3px">{{item.title}}</span>
        </div>
        <div style="font-size:18px;margin-top: 5px">{{item.msg}}</div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {Row,Col} from "element-ui"
export default {
  name: "index.vue",
  data () {
    return {

    }
  },
  components:{
    [Row.name]: Row,
    [Col.name]: Col,
  },
  props:{
    // 数据集
    dataList:{
      type:Array,
      require:true
    }
  }
}
</script>

<style scoped>

</style>