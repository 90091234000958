import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=0a9cf9e2&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&id=0a9cf9e2&lang=less&scoped=this%20api%20replaced%20by%20slot-scope%20in%202.5.0%2B&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a9cf9e2",
  null
  
)

export default component.exports