<template>
  <div style="background:#000;color: #fff;position: relative;">
    <el-row justify="center" type="flex" align="top">
      <el-col :span="18">
        <div style="height: 180px;display: flex;align-items: center;">
          <img src="../assets/logo.png" alt="" style="height: 64px">
          <div style="font-size: 18px;font-weight: bold">
            <div>联系电话：15571299098</div>
            <div>公司地址：湖北省孝感市保丽国际广场3栋25层</div>
          </div>
          <div style="flex: 1;position: relative">
            <slot></slot>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {Row,Col} from "element-ui"
export default {
  name: "footBox",
  components:{
    [Row.name]:Row,
    [Col.name]:Col
  }
}
</script>

<style scoped>

</style>